.dream_main{
  max-width: 500px;
  margin: 0 auto;
  background: #000;
  height: auto;
  padding: 48px;
  color: #fff;
  position: relative;
}
main {
  background: #11061c;
}
.white{
  filter: brightness(0) invert(1);
  cursor: pointer;
}
.gap-2{
  margin-left: 2rem;
}
.flex-two {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1rem;
}
.main_bg {
  background-image: url(../public/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.dream_main::before {
  position: absolute;
  content: "";
  left: 0;
  z-index: 0;
  background: radial-gradient(66.27% 66.26% at 41.2% 34.33%, #FC5AFF 0%, rgba(208, 75, 255, 0.00) 100%) !important;
  filter: blur(164.32041931152344px);
  width: 300px;
  height: 300px;
}
.dream_main::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  z-index: -1;
  background: radial-gradient(66.27% 66.26% at 41.2% 34.33%, #FC5AFF 0%, rgba(208, 75, 255, 0.00) 100%) !important;
  filter: blur(164.32041931152344px);
  width: 300px;
  height: 300px;
}
.gradient-bg {
  position: relative;
}
.heading{
  text-align: center;
}
.desc{
  text-align: center;
  margin: 2rem 0;
}
.desc p{
  font-size: 22px;
  line-height: 1.6;
  margin: 0;
}
.desc p span{
  font-weight: bold;
  color: #fc5aff;
}
ul.switch{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 16px;
  margin: 3rem 0rem;
}
ul.switch li {
  width: 100%;
  background: hsla(0,0%,100%,.05);
  text-align: center;
  border-radius: 50px;
  backdrop-filter: blur(8px);
  cursor: pointer;
  font-size: 14px;
  box-shadow: 4px 0px 1px 0px #fc5aff38;

}
.end {
  display: flex;
  align-items: center;
  justify-content: end;
}
.live {
  background: #3b1cf6;
  display: inline-block;
  width: 125px;
  border-radius: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
ul.switch li.active{
  background: linear-gradient(45deg, #fc5aff, #1710f3);
  box-shadow: 0px 0px 0px 0px #fc5aff38;
}
form.form_style {
  display: grid;
  gap: 1rem;
  position: relative;
  margin: 2rem 0rem;
}
form.form_style input {
  background: hsla(0, 0%, 100%, .05);
  backdrop-filter: blur(8px);
    border: 0.5px solid #ffffff3b;
  outline: none;
  height: 50px;
  border-radius: 50px;
  padding: 0rem 0rem 0rem 2rem;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
.max {
  position: absolute;
  right: 17px;
  top: 38px;
}
.receive_value {
  background: hsla(0, 0%, 100%, .05);
  backdrop-filter: blur(8px);
    border: 0.5px solid #ffffff3b;
  outline: none;
  height: 50px;
  border-radius: 50px;
  padding: 0rem 0rem 0rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
}
button {
  background: linear-gradient(45deg, #fc5aff, #1710f3);
  outline: none;
  border: navajowhite;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0rem;
  color: #fff;
  font-size: 19px;
  font-weight: bold;
}
label {
  font-size: 20px;
  font-weight: 600;
}
.a_tag a{
  width: 100%;
  height: 50px;
  background: hsla(0, 0%, 100%, .05);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;  color: #fff;
  text-decoration: none;
  border: 1px solid #9b3bfbb5;
  border-radius: 50px;
  font-size: 18px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0rem;
}
.flex img {
  /* width: 25px;
  height: 25px; */
  filter: brightness(0.5) invert(1);
}
.flex.icon a{
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #ff00d5, #0082ff);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #fff;
    cursor: pointer;
}
.range {
  border: 0.5px solid #fc5aff8a;
  width: 100%;
  height: 20px;
  border-radius: 50px;
  position: relative;
}
.range_clr {
  background: linear-gradient(45deg, #e954fe, #351af5);
  width: 60%;
  height: 20px;
  border-radius: 50px;
}
.pos{
  position: absolute;
  left: 50%;
  right: 50%;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  transform: translateX(-20px);
}
.desc h2 span{
  color: #ff00d5;
}
.link {
  text-align: center;
}
.link a{
  color: #0082ff;
}
.soldout h2{
  text-align: center;
  line-height: 1.6;
}
span.sold {
  color: red;
}
.annonce {
  text-align: center;
}
.flexy{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.flex_two{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.button {
  background: linear-gradient(45deg, #fc5aff, #1710f3);
  outline: none;
  border: navajowhite;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0rem;
  color: #fff;
  font-size: 19px;
  cursor: pointer;
  font-weight: bold;
  list-style-type: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
}
.share {
  background: linear-gradient(45deg, #fc5aff, #1710f3);
  outline: none;
  border: navajowhite;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  font-size: 19px;
  cursor: pointer;
  font-weight: bold;
  list-style-type: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
  margin-bottom: 2rem;
}
.share.cpy{
  background: linear-gradient(274deg, #fc5aff, #1710f3);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share.cpy img{
  filter: brightness(0) invert(1);
}
.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000c7;
  z-index: 1;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.close img{
  filter: brightness(0) invert(1);
}
.modal_content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 20px;
  color: #fff;
}
.modal_body {
  position: relative;
  background: linear-gradient(45deg, #351af5, black);
  border: 1px solid #fc5aff;
  max-width: 500px;
  margin: 0 auto;
  height: 200px;
  top: 0;
  bottom: 0;
  position: relative;
  border-radius: 12px;
  transform: translate(0px, 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexy p{
  width: 80%;
  word-wrap: break-word;
}
ol.orderlist {
  text-align: left;
  line-height: 24px;
  margin-bottom: 0rem;
}
/* media Query */

@media only screen and (max-width: 600px) {
  .dream_main{
    padding: 20px!important;
  }
  .desc p{
    font-size: 18px;
  }
  ul.switch{
    grid-template-columns: repeat(2, 1fr);
  }
  ul.switch li p{
    font-size: 12px;
  }
  label{
    font-size: 18px;
  }
  .a_tag a{
    font-size: 14px;
  }
}